<template>
  <div class="model-wrapper">
    <div v-for="(item, index) in list" :key="index">
      <div class="box f-j-c" :class="index % 2 == 0 ? 'f-column1' : 'f-column2'">
        <div class="f-s-36 t-c1 margin-tb36 f-j-c1 showflg1">{{ item.showTitle }}</div>
        <div class="w50 box f-j-c f-column">
          <img :src="item.showImg" alt="item.showTitle" class="show-img-wh">
        </div>
        <div class="w50 box f-j-c f-column">
          <div>
            <div class="f-s-36 t-c1 margin-tb36 f-j-c1 showflg">{{ item.showTitle }}</div>
            <div class="b-style">
              <div class="bg-server-name t-a-c">{{ item.serverName }}</div>
              <div class="f-s-24 t-c2 margin-tb12">{{ item.serverTitle }}</div>
              <div class="f-s-20 t-c3">{{ item.serverValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModelPage',
  props: {
    showIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      list: [
        {
          showImg: require('@/assets/func-icon1.png'),
          showTitle: '多种类五金商品',
          serverName: '各类商品',
          serverTitle: '专注于服务工矿企业、建筑工程、装修装饰、民用家居',
          serverValue: '五金商品的种类众多，为了用户更方便的搜索商品。商城分类明晰，分类完善，搭配图片，有利于提高用户的使用体验。',
        },
        {
          showImg: require('@/assets/func-icon2.png'),
          showTitle: '高效的仓储配送模式',
          serverName: '快速送达',
          serverTitle: '各市区配有专门仓库',
          serverValue: '行业内全新B2B+B2C运营模式，在全国各地级市设立仓储物流配送基地，各县级各镇设立配送中心仓库+门店。',
        },
        {
          showImg: require('@/assets/func-icon3.png'),
          showTitle: '实时管理订单',
          serverName: '订单管理',
          serverTitle: '发货追踪，掌握每个环节状态',
          serverValue: '可按多个维度查看下单、交易等记录，及时掌握每个订单的最新状态，还能追踪实物商品的物流状态。',
        },
        {
          showImg: require('@/assets/func-icon4.png'),
          showTitle: '上门安装服务',
          serverName: '专人服务',
          serverTitle: '简单便捷预约，优质上门服务',
          serverValue: '用户购买产品时遇到安装问题，可以在APP内直接预约上门安装师傅。',
        },
      ],
    };
  },
  computed: {

  },
  methods: {},
  created() {
    console.log('Model.vue created. showIndex:', this.showIndex);
  },
};
</script>

<style scoped>
  /* 你的 Model 组件的样式代码 */
  .bg-server-name {
    background-image: url("../assets/bg-server-name.png");
    background-repeat: no-repeat;
    width: 86px;
    height: 24px;
    background-size: contain;
  }

  .t-a-c {
    text-align: center;
    font-size: 0.75rem;
    padding-top: 6px;
    color: white;
  }

  .f-s-20 {
    font-size: 1.25rem;
  }

  .f-s-24 {
    font-size: 1.5rem;
  }

  .f-s-36 {
    font-size: 2.25rem;
    font-weight: bold;
  }

  .t-c1 {
    color: #1472FF
  }
  .t-c2 {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 34.75px;
    color: rgba(45, 134, 250, 1);
    text-align: left;
    vertical-align: top;
  }
  .t-c3 {
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 28.96px;
    color: rgba(110, 173, 255, 1);
    text-align: left;
    vertical-align: top;
    padding-right: 10px;
  }

  .box {
    display: -webkit-flex; /* Safari */
    display: flex;
  }

  .f-j-c {
    justify-content:center;
    align-items: center;
  }

  .f-column {
    flex-direction: column;
  }

  .margin-tb12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .margin-tb36 {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .margin-r88 {
    margin-right: 88px;
  }

  @media screen and (min-width: 0px) {
    .show-img-wh {
      width: 80%;
      max-width: 450px;
      max-height: 428px;
      object-fit: contain;
    }
    .w50 {
      width: 100%;
    }

    .f-column1 {
      flex-direction: column;
    }

    .f-column2 {
      flex-direction: column;
    }

    .f-j-c1 {
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content:center;
      align-items: center;
    }

    .b-style {
      border: 2px solid #6EADFF;
      border-radius: 2px;
      width: 90%;
      height: auto;
      max-width: 656px;
      min-height: 170px;
      padding-top: 15px;
      padding-left: 21px;
      box-shadow: 0px 72px 72px  rgba(110,173,255, 0.25);
      backdrop-filter: blur(4px);
      margin-bottom: 72px;
      padding-right: 12px;
    }

    .showflg1 {
      display: block;
    }

    .showflg {
      display: none;
    }
  }
  /* 3. 中等屏幕下 md 大于等于 992px */
  @media screen and (min-width: 1220px) {
    .show-img-wh {
      width: 450px;
      height: 428px;
      object-fit: contain;
    }
    .w50 {
      width: 50%;
    }

    .b-style {
      border: 2px solid #6EADFF;
      border-radius: 2px;
      height: 170px;
      padding-top: 15px;
      padding-left: 21px;
      box-shadow: 0px 72px 72px  rgba(110,173,255, 0.25);
      backdrop-filter: blur(4px);
    }

    .f-column1 {
      flex-direction: row;
    }

    .f-column2 {
      flex-direction: row-reverse;
    }

    .f-j-c1 {
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content:start;
    }

    .showflg1 {
      display: none;
    }

    .showflg {
      display: block;
    }
  }
</style>
