<template>
  <div class="container">
    <div>
      <div class="bg-img ">
        <div class="box">
          <div class="box-left">
            <div class="left-container">
              <div class="app-online"></div>
              <div class="t-size24 margin-tb10">全备建立线上、线下供应平台</div>
              <div class="t-size24 margin-tb10">实现全地配送服务</div>
              <div class="t-size12 margin-tb10">
                产品专注服务 工矿企业 | 建筑工程 | 装修装饰 | 民用家居
              </div>
            </div>
          </div>
          <div class="box-right">
            <div class="right-container">
              <div class="t-size-12">即刻扫码登录小程序，或者下载全备供应APP</div>
              <div class="right-box margin-t20">
                <div style="display: flex; flex-direction:column;">
                  <img src="../assets/xcx-btn.png" class="btn-img">
                  <img src="../assets/android-btn.png" class="btn-img margin-t10">
                  <img src="../assets/ios-btn.png" class="btn-img margin-t10">
                </div>
                <div>
                  <img src="../assets/xcx.png" class="wh-155 margin-l20">
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>

      <div class="">
        <div class="model-style">产品核心能力</div>
        <Model :showIndex="0" />
      </div>
    </div>
    <div style="margin-top: 100px;">
      <div class="right-box f-j-c">
        <div class="box w-760">
          <div class="right-box w50">
            <div class="f-s16 nav-style">关于我们</div>
            <div class="f-s16 nav-style">帮助中心</div>
            <div class="f-s16 nav-style">售后服务</div>
          </div>
          <div class="right-box w50">
            <div class="f-s16 nav-style">配送与验收</div>
            <div class="f-s16 nav-style">商务合作</div>
            <div class="f-s16 nav-style">企业采购</div>
          </div>
        </div>
      </div>

      <div class="right-box f-j-c  h-100">
        <div class="line"></div>
      </div>
      <div class="h-200">
        <div class="right-box f-j-c">
          <div style="text-align: center;margin-bottom: 10px;">增值电信业务经营许可证: 闽B2-20240352</div>
        </div>

        <div class="right-box f-j-c">
          <div style="text-align: center;">CopyRight @ 全备 2022 - 2025 闽ICP备2023000134号-2</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Model from './Model'


export default {
  name: 'HomePage',
  components: {Model},
  computed: {
  },
  methods: {
   
  },
  created(){
   
  }
}
</script>

<style scoped>  
  .container{
    width: 100%;
    height: 100%;
  }

  .app-online {
    height: 4rem;
    background: url("../assets/app-online.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .model-style {
    font-size: 3rem; 
    font-weight: bold; 
    text-align: center; 
    height: 150; 
    margin-top: 51px; 
    margin-bottom: 51px;
  }

  .margin-tb10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }


  .right-container {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btn-img {
    width: 160px; 
    height: 45px; 
  }

  .margin-t10 {
    margin-top: 10px;
  }

  .wh-155 {
    width: 155px; 
    height: 155px;
  }

  .margin-l20 {
    margin-left: 20px;
  }

  .margin-t20 {
    margin-top:  20px;
  }

  .right-box {
    display: -webkit-flex; /* Safari */
    display: flex;
  }

  .f-j-c {
    justify-content:center;
    align-items: center;
  }

  .line {
    width: 90%;
    height: 0px;
    opacity: 0.2;
    background: rgba(10, 20, 47, 1);
    border: 1px solid rgba(43, 61, 81, 1);
  }

  .t-size24 {
    font-size: 2.5rem;
    background: linear-gradient(90deg, #FFF 0%, rgb(183, 237, 252) 100%); 
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 700;
  }

  .t-size12 {
    font-size: 1.5rem;
    background: linear-gradient(90deg, #FFF 0%, rgb(53, 220, 242) 100%); 
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 400;
  }

  .t-size-12 {
    font-size: 1.5rem;
    color: #FFF;
    margin: auto;
  }

  .left-container {
    padding: 0 10px;
  }

  @media screen and (max-width: 575.98px) {
    .app-online {
      height: 2rem;
      background: url("../assets/app-online.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    .box-left {
      width: 100%;
      height: 270px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box-right {
      width: 100%;
      height: 270px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -40px;
    }

    .bg-img {
      background: url("../assets/bg-header.png") no-repeat;
      height: 500px;
    }

    .w-760 {
      width: 380px;
    }

    .w50 {
      width: 100%;
    }

    .h-100 {
      height: 50px;
    }

    .h-200 {
      height: 100px;
    }

    .nav-style {
      width: 33%;
      margin-top: 21px;
      margin-bottom: 21px;
      text-align: center;
    }

    .t-size24 {
      font-size: 1.5rem;
      background: linear-gradient(90deg, #FFF 0%, rgb(183, 237, 252) 100%); 
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    .t-size12 {
      font-size: 1rem;
      background: linear-gradient(90deg, #FFF 0%, rgb(53, 220, 242) 100%); 
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 400;
    }

    .t-size-12 {
      font-size: 1rem;
      color: #FFF;
      margin: auto;
    }
  }


  @media screen and (min-width: 576px) and (max-width: 991.98px) {
    .box-left {
      width: 100%;
      height: 345px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
    }

    .box-right {
      width: 100%;
      height: 345px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -40px;
    }

    .bg-img {
      background: url("../assets/bg-header.png") no-repeat;
      height: 700px;
    }

    .w-760 {
      width: 380px;
    }

    .w50 {
      width: 100%;
    }

    .h-100 {
      height: 50px;
    }

    .h-200 {
      height: 100px;
    }

    .nav-style {
      width: 33%;
      margin-top: 21px;
      margin-bottom: 21px;
      text-align: center;
    }

    .t-size24 {
      font-size: 2.5rem;
      background: linear-gradient(90deg, #FFF 0%, rgb(183, 237, 252) 100%); 
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    .t-size12 {
      font-size: 1.5rem;
      background: linear-gradient(90deg, #FFF 0%, rgb(53, 220, 242) 100%); 
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 400;
    }

    .t-size-12 {
      font-size: 1.5rem;
      color: #FFF;
      margin: auto;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .box-left {
      width: 100%;
      height: 345px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
    }

    .box-right {
      width: 100%;
      height: 345px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -40px;
    }

    .bg-img {
      background: url("../assets/bg-header.png") no-repeat;
      height: 700px;
    }

    .w-760 {
      width: 380px;
    }

    .w50 {
      width: 100%;
    }

    .h-100 {
      height: 50px;
    }

    .h-200 {
      height: 100px;
    }

    .nav-style {
      width: 33%;
      margin-top: 21px;
      margin-bottom: 21px;
      text-align: center;
    }

    .t-size24 {
      font-size: 2.5rem;
      background: linear-gradient(90deg, #FFF 0%, rgb(183, 237, 252) 100%); 
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 700;
    }

    .t-size12 {
      font-size: 1.5rem;
      background: linear-gradient(90deg, #FFF 0%, rgb(53, 220, 242) 100%); 
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 400;
    }

    .t-size-12 {
      font-size: 1.5rem;
      color: #FFF;
      margin: auto;
    }
  }

  /* 3. 中等屏幕下 md 大于等于 1200px */
  @media screen and (min-width: 1200px) {
    .bg-img {
      background: url("../assets/bg-header.png") no-repeat;
      width: 100%;
      height: 450px;
    }

    .box {
      display: -webkit-flex; /* Safari */
      display: flex;
    }

    .box-left {
      width: 50%;
      height: 450px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0;
    }

    .box-right {
      width: 50%;
      height: 450px;
      display: -webkit-flex; /* Safari */
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
    }

    .w-760 {
      width: 760px;
    }

    .w50 {
      width: 50%;
    }

    .h-100 {
      height: 100px;
    }

    .h-200 {
      height: 100px;
    }

    
    .nav-style {
      width: 33%;
      margin-top: 42px;
      margin-bottom: 42px;
    }
  }
</style>
